<template>
  <div class="ba_lin_you_qi_map">
    <!-- <v-chart :options="options" autoresize @click="clickMap" /> -->
    <v-chart :options="options" autoresize @click="clickMap" />
  </div>
</template>

<script>
import * as echarts from "echarts";
import '../../../../assets/js/map/xing-fu-zhi-lu.js'
import { getXiangZhenTongJi } from '../../../../config/api';

//https://www.makeapie.com/editor.html?c=x2yaz6dfRw
//https://www.makeapie.com/editor.html?c=xMpGBbTEKU
let geoCoordMap = {};
let data = [
  {name: "索博日嘎镇", value: 23},
  {name: "幸福之路苏木", value: 524},
  {name: "巴彦琥硕镇", value: 14},
  {name: "查干沐沦苏木", value: 150},
  {name: "大板镇", value: 75},
  {name: "大板煤电化基地", value: 13},
  {name: "巴彦塔拉苏木", value: 83},
  {name: "查干诺尔镇", value: 11},
  {name: "宝日勿苏镇", value: 19},
  {name: "西拉沐沦苏木", value: 15}   
];

let convertData = function (data) {
  let res = [];
  for (let i = 0; i < data.length; i++) {
    let geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value),
      });
    }
  }
  return res;
};

let max = 6000,
  min = 10;
let maxSize4Pin = 100,
  minSize4Pin = 20;

export default {
  name: "xingFuZhiLuMap",
  props: {
    value: Object,
    ispreview: Boolean,
  },
  data() {
    return {
      xiangZhenTongJi: [],  // 乡镇统计
      gaChaCunTongJi: [],   // 嘎查村统计
      oneGaChaCunTongJi: [],   // 一个嘎查村统计
      showGaChaCunTongJi: false,
      showOneGaChaCunTongJi: false,

      isShowDialog: false,
      dialogtTitle: '',
      userClick: false,
      dialogTitle: '',
      dialogHamletTitle: '',

      zhuZhaungTuData: {},

      map: null,
      mapName: '索博日嘎镇',
      options: {
        //backgroundColor: '#0F1C3C',
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params.value.length > 1) {
              return params.data.name + "" + params.data.value[2];
            } else {
              return params.name;
            }
          },
        },
        geo: {
          map: "xingfuzhilu",
          show: true,
          roam: false,
          label: {
            emphasis: {
              show: false,
            },
          },
          layoutSize: "80%",
          itemStyle: {
            normal: {
              borderColor: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#00F6FF",
                  },
                  {
                    offset: 1,
                    color: "#53D9FF",
                  },
                ],
                false
              ),
              borderWidth: 3,
              shadowColor: "rgba(10,76,139,1)",
              shadowOffsetY: 0,
              shadowBlur: 60,
            },
          },
        },
        series: [
          {
            type: "map",
            map: "xingfuzhilu",
            aspectScale: 0.75,
            label: {
              normal: {
                //调整数值
                position: "right",
                show: true,
                color: "#53D9FF",
                fontSize: 14,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                //地图块颜色
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#061E3D", // 100% 处的颜色
                    },
                  ],
                },
                borderColor: "#215495",
                borderWidth: 1,
              },
              //鼠标放置颜色加深
              emphasis: {
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#2B91B7", // 100% 处的颜色
                    },
                  ],
                },
              },
            },
            data: data,
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "stroke",
            },
            showEffectOn: "render",
            itemStyle: {
              normal: {
                //气泡颜色
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(5,80,151,0.2)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(5,80,151,0.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,108,255,0.7)",
                    },
                  ],
                  global: false,
                },
              },
            },
            label: {
              normal: {
                show: true,
                color: "#fff",
                fontWeight: "bold",
                position: "inside",
                formatter: function (para) {
                  return "{cnNum|" + para.data.value[2] + "}";
                },
                rich: {
                  cnNum: {
                    fontSize: 13,
                    color: "#D4EEFF",
                  },
                },
              },
            },
            symbol: "circle",
            symbolSize: function (val) {
              if (val[2] == 0) {
                return 0;
              }
              return (
                ((maxSize4Pin - minSize4Pin) / (max - min)) * val[2] +
                (maxSize4Pin -
                  ((maxSize4Pin - minSize4Pin) / (max - min)) * max) *
                  1.2
              );
            },
            data: convertData(data),
            zlevel: 1,
          },
        ],
      },
      optionsStyle: {}, // 样式
      optionsData: {}, // 数据
      optionsCollapse: {}, // 图标属性
      optionsSetup: {},
    };
  },
  computed: {
    styleObj() {
      return {
        position: this.ispreview ? "absolute" : "static",
        width: this.optionsStyle.width + "px",
        height: this.optionsStyle.height + "px",
        left: this.optionsStyle.left + "px",
        top: this.optionsStyle.top + "px",
        background: this.optionsSetup.background,
      };
    },
  },
  watch: {
    value: {
      handler(val) {
        this.optionsStyle = val.position;
        this.optionsData = val.data;
        this.optionsCollapse = val.setup;
        this.optionsSetup = val.setup;
        this.editorOptions();
      },
      deep: true,
    },
  },
  mounted() {
    this.getXiangZhenTongJi()

    setTimeout(() => {
      this.clickMap({name: "索博日嘎镇", isInit: true})
    }, 600)
    

    this.optionsStyle = this.value.position;
    this.optionsData = this.value.data;
    this.optionsCollapse = this.value.setup;
    this.optionsSetup = this.value.setup;
    this.editorOptions();
    this.changeData()
  },
  methods: {
    // 每隔 3分钟 更新大屏数据
    changeData() {
      let i = 0
      setInterval(() => {
        if (i == 9) {
          i = 0
        } else {
          i ++
        }
        this.clickMap(data[i])
        this.mapName = data[i].name
      }, 180 * 1000)
    },

    // 获取乡镇统计
    async getXiangZhenTongJi() {
      let res = await getXiangZhenTongJi()
      // console.log('获取 乡镇统计 : ', res.data.results);
      this.xiangZhenTongJi = res.data.results
    },

    // 点击 map
    clickMap(e) {
      // this.dialogTitle = `获取${ e.name }的全部嘎查村牲畜存栏统计`

      this.mapName = e.name

      var params = {
        mapName: '',
        zhong_zhi_zhong_lei: {},
        yang_zhi_lei: {},
        chan_liang: {},
        bing_chong_hai: {},
        zhi_neng_hua: {},
        person_data: [],
        mashine_data: []
      }

      if (e.name == '索博日嘎镇') {
        // 乡镇名称
        params.mapName = e.name
        // 种植种类
        params.zhong_zhi_zhong_lei = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 5000},
            {"name": "甜玉米", "value": 2000},
            {"name": "小麦", "value": 2000},
            {"name": "葵花", "value": 10000},
            {"name": "马铃薯", "value": 2000},
            {"name": "胡萝卜", "value": 2000},
            {"name": "香瓜", "value": 2000},
            {"name": "芸豆", "value": 500}
          ]
        }
        // 养殖类
        params.yang_zhi_lei = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 2357},
            {"name": "马", "value": 5432},
            {"name": "绵羊", "value": 2521},
            {"name": "山羊", "value": 1676},
            {"name": "驴", "value": 243},
            {"name": "骆驼", "value": 321}
          ]
        }
        params.chan_liang = {
          title: '产量统计',
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [34, 45, 72, 73, 124, 142, 43, 87, 89]
        }
        params.bing_chong_hai = {
          title: '病虫害统计',
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [12, 19, 32, 23, 28]
        }
        params.zhi_neng_hua = {
          title: '智能化统计',
          yName: '件',
          x: ['传送带', '开关', '监控', '搅拌机', '饮水机'],
          y: [14, 26, 62, 73, 128]
        }
        // 人口统计
        params.person_data = [
          { name: '20岁以下', value: 4133 },
          { name: '20 - 30岁', value: 4128 },
          { name: '30 - 50岁', value: 10058 },
          { name: '50岁以上', value: 5352 }
        ]
        // 机械化统计
        params.mashine_data = [
          { name: ' 四轮车', value: 3500 },
          { name: '大型货车', value: 18 },
          { name: '大型拖拉机', value: 91 },
          { name: '播种机械', value: 2800 },
          { name: '打草机械', value: 580 },
          { name: '家用车辆', value: 450 },
        ]
      } else if (e.name == '幸福之路苏木') {
        // 乡镇名称
        params.mapName = e.name
        // 种植种类
        params.zhong_zhi_zhong_lei = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 2000},
            {"name": "甜玉米", "value": 1000},
            {"name": "小麦", "value": 1000},
            {"name": "葵花", "value": 5000},
            {"name": "马铃薯", "value": 1000},
            {"name": "胡萝卜", "value": 1500},
            {"name": "香瓜", "value": 1700},
            {"name": "芸豆", "value": 1000}
          ]
        }
        // 养殖类
        params.yang_zhi_lei = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 557},
            {"name": "马", "value": 632},
            {"name": "绵羊", "value": 521},
            {"name": "山羊", "value": 676},
            {"name": "驴", "value": 1243},
            {"name": "骆驼", "value": 421}
          ]
        }
        params.chan_liang = {
          title: '产量统计',
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [14, 45, 72, 73, 24, 42, 43, 87, 89]
        }
        params.bing_chong_hai = {
          title: '病虫害统计',
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [43, 19, 12, 23, 28]
        }
        params.zhi_neng_hua = {
          title: '智能化统计',
          yName: '件',
          x: ['传送带', '开关', '监控', '搅拌机', '饮水机'],
          y: [32, 16, 32, 73, 68]
        }
        // 人口统计
        params.person_data = [
          { name: '20岁以下', value: 2133 },
          { name: '20 - 30岁', value: 5128 },
          { name: '30 - 50岁', value: 12058 },
          { name: '50岁以上', value: 2352 }
        ]
        // 机械化统计
        params.mashine_data = [
          { name: ' 四轮车', value: 1500 },
          { name: '大型货车', value: 28 },
          { name: '大型拖拉机', value: 54 },
          { name: '播种机械', value: 2300 },
          { name: '打草机械', value: 320 },
          { name: '家用车辆', value: 430 },
        ]
      } else if (e.name == '巴彦琥硕镇') {
        // 乡镇名称
        params.mapName = e.name
        // 种植种类
        params.zhong_zhi_zhong_lei = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 1000},
            {"name": "甜玉米", "value": 23300},
            {"name": "小麦", "value": 54600},
            {"name": "葵花", "value": 34000},
            {"name": "马铃薯", "value": 5000},
            {"name": "胡萝卜", "value": 6700},
            {"name": "香瓜", "value": 3200},
            {"name": "芸豆", "value": 1400}
          ]
        }
        // 养殖类
        params.yang_zhi_lei = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 4357},
            {"name": "马", "value": 1432},
            {"name": "绵羊", "value": 1521},
            {"name": "山羊", "value": 1276},
            {"name": "驴", "value": 143},
            {"name": "骆驼", "value": 721}
          ]
        }
        params.chan_liang = {
          title: '产量统计',
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [54, 25, 82, 73, 24, 42, 43, 87, 89]
        }
        params.bing_chong_hai = {
          title: '病虫害统计',
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [23, 19, 30, 23, 38]
        }
        params.zhi_neng_hua = {
          title: '智能化统计',
          yName: '件',
          x: ['传送带', '开关', '监控', '搅拌机', '饮水机'],
          y: [14, 26, 32, 13, 58]
        }
        // 人口统计
        params.person_data = [
          { name: '20岁以下', value: 2133 },
          { name: '20 - 30岁', value: 4728 },
          { name: '30 - 50岁', value: 9058 },
          { name: '50岁以上', value: 3352 }
        ]
        // 机械化统计
        params.mashine_data = [
          { name: ' 四轮车', value: 1500 },
          { name: '大型货车', value: 43 },
          { name: '大型拖拉机', value: 71 },
          { name: '播种机械', value: 3400 },
          { name: '打草机械', value: 340 },
          { name: '家用车辆', value: 540 },
        ]
      } else if (e.name == '查干沐沦苏木') {
        // 乡镇名称
        params.mapName = e.name
        // 种植种类
        params.zhong_zhi_zhong_lei = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 1000},
            {"name": "甜玉米", "value": 1000},
            {"name": "小麦", "value": 1000},
            {"name": "葵花", "value": 12000},
            {"name": "马铃薯", "value": 1000},
            {"name": "胡萝卜", "value": 2000},
            {"name": "香瓜", "value": 4000},
            {"name": "芸豆", "value": 100}
          ]
        }
        // 养殖类
        params.yang_zhi_lei = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 1357},
            {"name": "马", "value": 4432},
            {"name": "绵羊", "value": 3521},
            {"name": "山羊", "value": 2676},
            {"name": "驴", "value": 143},
            {"name": "骆驼", "value": 21}
          ]
        }
        params.chan_liang = {
          title: '产量统计',
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [14, 45, 72, 73, 64, 42, 43, 87, 49]
        }
        params.bing_chong_hai = {
          title: '病虫害统计',
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [12, 39, 32, 13, 28]
        }
        params.zhi_neng_hua = {
          title: '智能化统计',
          yName: '件',
          x: ['传送带', '开关', '监控', '搅拌机', '饮水机'],
          y: [14, 26, 32, 13, 48]
        }
        // 人口统计
        params.person_data = [
          { name: '20岁以下', value: 1133 },
          { name: '20 - 30岁', value: 2128 },
          { name: '30 - 50岁', value: 7058 },
          { name: '50岁以上', value: 352 }
        ]
        // 机械化统计
        params.mashine_data = [
          { name: ' 四轮车', value: 3100 },
          { name: '大型货车', value: 43 },
          { name: '大型拖拉机', value: 71 },
          { name: '播种机械', value: 1800 },
          { name: '打草机械', value: 380 },
          { name: '家用车辆', value: 250 },
        ]
      } else if (e.name == '大板镇') {
        // 乡镇名称
        params.mapName = e.name
        // 种植种类
        params.zhong_zhi_zhong_lei = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 900},
            {"name": "甜玉米", "value": 1000},
            {"name": "小麦", "value": 1000},
            {"name": "葵花", "value": 8700},
            {"name": "马铃薯", "value": 400},
            {"name": "胡萝卜", "value": 2000},
            {"name": "香瓜", "value": 4000},
            {"name": "芸豆", "value": 100}
          ]
        }
        // 养殖类
        params.yang_zhi_lei = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 357},
            {"name": "马", "value": 432},
            {"name": "绵羊", "value": 521},
            {"name": "山羊", "value": 2676},
            {"name": "驴", "value": 143},
            {"name": "骆驼", "value": 21}
          ]
        }
        params.chan_liang = {
          title: '产量统计',
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [42, 45, 72, 23, 64, 102, 43, 47, 89]
        }
        params.bing_chong_hai = {
          title: '病虫害统计',
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [12, 9, 12, 23, 28]
        }
        params.zhi_neng_hua = {
          title: '智能化统计',
          yName: '件',
          x: ['传送带', '开关', '监控', '搅拌机', '饮水机'],
          y: [14, 26, 82, 73, 28]
        }
        // 人口统计
        params.person_data = [
          { name: '20岁以下', value: 933 },
          { name: '20 - 30岁', value: 528 },
          { name: '30 - 50岁', value: 7058 },
          { name: '50岁以上', value: 352 }
        ]
        // 机械化统计
        params.mashine_data = [
          { name: ' 四轮车', value: 8700 },
          { name: '大型货车', value: 43 },
          { name: '大型拖拉机', value: 71 },
          { name: '播种机械', value: 300 },
          { name: '打草机械', value: 380 },
          { name: '家用车辆', value: 250 },
        ]
      } else if (e.name == '大板煤电化基地') {
        // 乡镇名称
        params.mapName = e.name
        // 种植种类
        params.zhong_zhi_zhong_lei = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 1200},
            {"name": "甜玉米", "value": 4300},
            {"name": "小麦", "value": 1200},
            {"name": "葵花", "value": 4300},
            {"name": "马铃薯", "value": 1000},
            {"name": "胡萝卜", "value": 2000},
            {"name": "香瓜", "value": 4000},
            {"name": "芸豆", "value": 100}
          ]
        }
        // 养殖类
        params.yang_zhi_lei = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 1357},
            {"name": "马", "value": 4232},
            {"name": "绵羊", "value": 3521},
            {"name": "山羊", "value": 1676},
            {"name": "驴", "value": 143},
            {"name": "骆驼", "value": 21}
          ]
        }
        params.chan_liang = {
          title: '产量统计',
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [14, 45, 72, 23, 24, 42, 43, 87, 39]
        }
        params.bing_chong_hai = {
          title: '病虫害统计',
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [43, 39, 12, 63, 48]
        }
        params.zhi_neng_hua = {
          title: '智能化统计',
          yName: '件',
          x: ['传送带', '开关', '监控', '搅拌机', '饮水机'],
          y: [14, 16, 62, 33, 18]
        }
        // 人口统计
        params.person_data = [
          { name: '20岁以下', value: 1033 },
          { name: '20 - 30岁', value: 2128 },
          { name: '30 - 50岁', value: 1058 },
          { name: '50岁以上', value: 352 }
        ]
        // 机械化统计
        params.mashine_data = [
          { name: ' 四轮车', value: 2310 },
          { name: '大型货车', value: 43 },
          { name: '大型拖拉机', value: 71 },
          { name: '播种机械', value: 1600 },
          { name: '打草机械', value: 380 },
          { name: '家用车辆', value: 250 },
        ]
      } else if (e.name == '巴彦塔拉苏木') {
        // 乡镇名称
        params.mapName = e.name
        // 种植种类
        params.zhong_zhi_zhong_lei = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 1440},
            {"name": "甜玉米", "value": 1000},
            {"name": "小麦", "value": 1000},
            {"name": "葵花", "value": 5970},
            {"name": "马铃薯", "value": 1200},
            {"name": "胡萝卜", "value": 2000},
            {"name": "香瓜", "value": 4000},
            {"name": "芸豆", "value": 100}
          ]
        }
        // 养殖类
        params.yang_zhi_lei = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 1357},
            {"name": "马", "value": 1432},
            {"name": "绵羊", "value": 3221},
            {"name": "山羊", "value": 2676},
            {"name": "驴", "value": 143},
            {"name": "骆驼", "value": 21}
          ]
        }
        params.chan_liang = {
          title: '产量统计',
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [24, 45, 72, 73, 54, 62, 43, 87, 35]
        }
        params.bing_chong_hai = {
          title: '病虫害统计',
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [23, 19, 72, 23, 38]
        }
        params.zhi_neng_hua = {
          title: '智能化统计',
          yName: '件',
          x: ['传送带', '开关', '监控', '搅拌机', '饮水机'],
          y: [32, 26, 32, 73, 88]
        }
        // 人口统计
        params.person_data = [
          { name: '20岁以下', value: 1033 },
          { name: '20 - 30岁', value: 2128 },
          { name: '30 - 50岁', value: 5058 },
          { name: '50岁以上', value: 352 }
        ]
        // 机械化统计
        params.mashine_data = [
          { name: ' 四轮车', value: 2100 },
          { name: '大型货车', value: 43 },
          { name: '大型拖拉机', value: 71 },
          { name: '播种机械', value: 1860 },
          { name: '打草机械', value: 380 },
          { name: '家用车辆', value: 250 },
        ]
      } else if (e.name == '查干诺尔镇') {
        // 乡镇名称
        params.mapName = e.name
        // 种植种类
        params.zhong_zhi_zhong_lei = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 800},
            {"name": "甜玉米", "value": 2000},
            {"name": "小麦", "value": 1000},
            {"name": "葵花", "value": 4500},
            {"name": "马铃薯", "value": 1000},
            {"name": "胡萝卜", "value": 2000},
            {"name": "香瓜", "value": 3200},
            {"name": "芸豆", "value": 100}
          ]
        }
        // 养殖类
        params.yang_zhi_lei = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 1257},
            {"name": "马", "value": 4432},
            {"name": "绵羊", "value": 2521},
            {"name": "山羊", "value": 2376},
            {"name": "驴", "value": 143},
            {"name": "骆驼", "value": 21}
          ]
        }
        params.chan_liang = {
          title: '产量统计',
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [54, 45, 72, 73, 24, 12, 43, 87, 89]
        }
        params.bing_chong_hai = {
          title: '病虫害统计',
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [42, 19, 12, 23, 28]
        }
        params.zhi_neng_hua = {
          title: '智能化统计',
          yName: '件',
          x: ['传送带', '开关', '监控', '搅拌机', '饮水机'],
          y: [14, 26, 35, 73, 88]
        }
        // 人口统计
        params.person_data = [
          { name: '20岁以下', value: 1033 },
          { name: '20 - 30岁', value: 2128 },
          { name: '30 - 50岁', value: 6058 },
          { name: '50岁以上', value: 352 }
        ]
        // 机械化统计
        params.mashine_data = [
          { name: ' 四轮车', value: 1100 },
          { name: '大型货车', value: 43 },
          { name: '大型拖拉机', value: 71 },
          { name: '播种机械', value: 3800 },
          { name: '打草机械', value: 380 },
          { name: '家用车辆', value: 250 },
        ]
      } else if (e.name == '宝日勿苏镇') {
        // 乡镇名称
        params.mapName = e.name
        // 种植种类
        params.zhong_zhi_zhong_lei = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 1000},
            {"name": "甜玉米", "value": 1000},
            {"name": "小麦", "value": 1000},
            {"name": "葵花", "value": 12000},
            {"name": "马铃薯", "value": 1000},
            {"name": "胡萝卜", "value": 2000},
            {"name": "香瓜", "value": 4000},
            {"name": "芸豆", "value": 100}
          ]
        }
        // 养殖类
        params.yang_zhi_lei = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 1357},
            {"name": "马", "value": 4432},
            {"name": "绵羊", "value": 3521},
            {"name": "山羊", "value": 2676},
            {"name": "驴", "value": 143},
            {"name": "骆驼", "value": 21}
          ]
        }
        params.chan_liang = {
          title: '产量统计',
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [54, 45, 72, 33, 24, 42, 43, 87, 89]
        }
        params.bing_chong_hai = {
          title: '病虫害统计',
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [12, 39, 12, 23, 28]
        }
        params.zhi_neng_hua = {
          title: '智能化统计',
          yName: '件',
          x: ['传送带', '开关', '监控', '搅拌机', '饮水机'],
          y: [14, 76, 62, 23, 28]
        }
        // 人口统计
        params.person_data = [
          { name: '20岁以下', value: 1133 },
          { name: '20 - 30岁', value: 2128 },
          { name: '30 - 50岁', value: 7058 },
          { name: '50岁以上', value: 352 }
        ]
        // 机械化统计
        params.mashine_data = [
          { name: ' 四轮车', value: 3100 },
          { name: '大型货车', value: 43 },
          { name: '大型拖拉机', value: 71 },
          { name: '播种机械', value: 1800 },
          { name: '打草机械', value: 380 },
          { name: '家用车辆', value: 250 },
        ]
      } else if (e.name == '西拉沐沦苏木') {
        // 乡镇名称
        params.mapName = e.name
        // 种植种类
        params.zhong_zhi_zhong_lei = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 500},
            {"name": "甜玉米", "value": 7000},
            {"name": "小麦", "value": 1000},
            {"name": "葵花", "value": 5000},
            {"name": "马铃薯", "value": 4000},
            {"name": "胡萝卜", "value": 2000},
            {"name": "香瓜", "value": 4000},
            {"name": "芸豆", "value": 100}
          ]
        }
        // 养殖类
        params.yang_zhi_lei = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 1357},
            {"name": "马", "value": 2432},
            {"name": "绵羊", "value": 3521},
            {"name": "山羊", "value": 1676},
            {"name": "驴", "value": 143},
            {"name": "骆驼", "value": 21}
          ]
        }
        params.chan_liang = {
          title: '产量统计',
          yName: '吨',
          x: ['玉米', '甜玉米', '芸豆', '胡萝卜', '小麦', '葵花', '马铃薯', '香瓜'],
          y: [34, 45, 72, 73, 54, 42, 43, 87, 29]
        }
        params.bing_chong_hai = {
          title: '病虫害统计',
          yName: '万',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [12, 54, 32, 13, 28]
        }
        params.zhi_neng_hua = {
          title: '智能化统计',
          yName: '件',
          x: ['传送带', '开关', '监控', '搅拌机', '饮水机'],
          y: [14, 46, 32, 73, 18]
        }
        // 人口统计
        params.person_data = [
          { name: '20岁以下', value: 1133 },
          { name: '20 - 30岁', value: 1128 },
          { name: '30 - 50岁', value: 3058 },
          { name: '50岁以上', value: 352 }
        ]
        // 机械化统计
        params.mashine_data = [
          { name: ' 四轮车', value: 1100 },
          { name: '大型货车', value: 43 },
          { name: '大型拖拉机', value: 71 },
          { name: '播种机械', value: 2800 },
          { name: '打草机械', value: 380 },
          { name: '家用车辆', value: 250 },
        ]
      } 

      // 发送点击事件
      this.$bus.$emit('setData', params)      
    },

    // 跳转到详情页面
    goToDetail(val) {
      // 方案1
      this.$router.push({
        path: "/bigscreen/detail/" + val
      })

      // 方案2
      // localStorage.setItem('village', val)
    },

    // 修改图标options属性
    editorOptions() {
      this.setOptionsTitle();
      this.setOptionTextValue();
      this.setOptionsData();
      this.setOptionAirSize();
      this.setOptionMapBlocak();
    },
    // 标题设置
    setOptionsTitle() {
      const optionsSetup = this.optionsSetup;
      const title = {};
      title.text = optionsSetup.titleText;
      title.show = optionsSetup.isNoTitle;
      title.left = optionsSetup.textAlign;
      title.textStyle = {
        color: optionsSetup.textColor,
        fontSize: optionsSetup.textFontSize,
        fontWeight: optionsSetup.textFontWeight,
        fontStyle: optionsSetup.textFontStyle,
      };
      title.subtext = optionsSetup.subText;
      title.subtextStyle = {
        color: optionsSetup.subTextColor,
        fontWeight: optionsSetup.subTextFontWeight,
        fontSize: optionsSetup.subTextFontSize,
        fontStyle: optionsSetup.subTextFontStyle,
      };
      this.options.title = title;
    },
    setOptionTextValue() {
      const optionsSetup = this.optionsSetup;
      const label = this.options.series[0]["label"];
      const normal = {
        position: "right",
        show: optionsSetup.isShowMap,
        color: optionsSetup.fontTextColor,
        fontSize: optionsSetup.fontTextSize,
        fontWeight: optionsSetup.fontTextWeight,
      };
      label["normal"] = normal;
    },

    setOptionMapBlocak() {
      const optionsSetup = this.optionsSetup;
      const itemStyle = this.options.series[0]["itemStyle"];
      const normal = {
        //地图块颜色
        areaColor: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: optionsSetup.font0PreColor, // 0% 处的颜色
            },
            {
              offset: 1,
              color: optionsSetup.font100PreColor, // 100% 处的颜色
            },
          ],
        },
        borderColor: "#215495",
        borderWidth: 1,
      };
      //鼠标放置颜色加深
      const emphasis = {
        areaColor: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#073684", // 0% 处的颜色
            },
            {
              offset: 1,
              color: optionsSetup.fontHighlightColor, // 100% 处的颜色
            },
          ],
        },
      };
      itemStyle["normal"] = normal;
      itemStyle["emphasis"] = emphasis;
    },
    setOptionAirSize() {
      maxSize4Pin = this.optionsSetup.fontmaxSize4Pin;
      minSize4Pin = this.optionsSetup.fontminSize4Pin;
    },
    //数据解析
    setOptionsData() {
      const optionsData = this.optionsData; // 数据类型 静态 or 动态
      optionsData.dataType == "staticData"
        ? this.staticDataFn(optionsData.staticData)
        : this.dynamicDataFn(optionsData.dynamicData, optionsData.refreshTime);
    },
    staticDataFn(val) {
      this.options.series[0]["data"] = val;
      const optionsSetup = this.optionsSetup;
      const label = this.options.series[1]["label"];
      const normal = {
        show: true,
        color: "#fff",
        fontWeight: "bold",
        position: "inside",
        formatter: function (para) {
          return "{cnNum|" + para.data.value[2] + "}";
        },
        rich: {
          cnNum: {
            fontSize: optionsSetup.fontDataSize,
            color: optionsSetup.fontDataColor,
            fontWeight: optionsSetup.fontDataWeight,
          },
        },
      };
      const data = convertData(val);
      this.options.series[1]["data"] = data;
      label["normal"] = normal;
    },
    dynamicDataFn(val, refreshTime) {
      if (!val) return;
      if (this.ispreview) {
        this.getEchartData(val);
        this.flagInter = setInterval(() => {
          this.getEchartData(val);
        }, refreshTime);
      } else {
        this.getEchartData(val);
      }
    },
    getEchartData(val) {
      const data = this.queryEchartsData(val);
      data.then((res) => {
        this.renderingFn(res);
      });
    },
    renderingFn(val) {
      this.options.series[0]["data"] = val;
      const optionsSetup = this.optionsSetup;
      const label = this.options.series[1]["label"];
      const normal = {
        show: true,
        color: "#fff",
        fontWeight: "bold",
        position: "inside",
        formatter: function (para) {
          return "{cnNum|" + para.data.value[2] + "}";
        },
        rich: {
          cnNum: {
            fontSize: optionsSetup.fontDataSize,
            color: optionsSetup.fontDataColor,
            fontWeight: optionsSetup.fontDataWeight,
          },
        },
      };
      const data = convertData(val);
      this.options.series[1]["data"] = data;
      label["normal"] = normal;
    },
  },
};
</script>
<style lang="scss" scoped>

.ba_lin_you_qi_map {
  width: 100%;
  height: 100%;
}

.echartMap {
  width: 100%;
  height: 100%;
  color: #d4eeff;
}

.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.village {
  font-size: 26px;
  color: #fff;
  position: relative;
  top: 70px;
  left: 70px;
  z-index: 9999999;
}
.village:hover {
  cursor: pointer !important;
  color: #2366fc !important;
}

.tong_ji_dialog {
  width: 900px;
  height: 700px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  z-index: 99999;

  .tong_ji_title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #2366fc;
    color: #fff;
    font-size: 20px;
    
  }

  .tong_ji_list {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    // border: 1px solid #f00;
    .tong_ji_item {
      padding: 10px;
      border-bottom: 1px solid #eee;
      .tong_ji_name {
        text-align: left;
        padding: 10px;
        font-size: 18px;
        color: #212121;
        cursor: pointer;
        font-weight: bold;
      }

      span { font-size: 18px; margin-right: 20px; }
    }
  }

  .footer_btn {
    margin-top: 20px;
  }
}

// 询问跳转 dialog
.xun_wen_dialog {
  width: 100%;
  height: 100%;
  background-color: rgba(#212121, .8);
  position: relative;
  top: 0;
  left: 0;

  .xun_wen {
    width: 50%;
    height: 300px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;

    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #2366fc;
      color: #fff;
      font-size: 20px;
    }

    .footer_btn {
      margin-top: 20px;
    }
  }
}

.mo-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.el-dialog__wrapper {
  background-color: rgba(#212121, .6);

  .el-dialog {
    margin-top: 47% !important;
  }
}
.el-dialog {  margin-top: 47% !important; }
</style>
