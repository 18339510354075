import { render, staticRenderFns } from "./ba-yan-ta-la.vue?vue&type=template&id=28de6d97&scoped=true&"
import script from "./ba-yan-ta-la.vue?vue&type=script&lang=js&"
export * from "./ba-yan-ta-la.vue?vue&type=script&lang=js&"
import style0 from "./ba-yan-ta-la.vue?vue&type=style&index=0&id=28de6d97&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28de6d97",
  null
  
)

export default component.exports